import React from 'react'

import ArticlesItem from '../elements/ArticlesItem'

function Articles({ articles }) {
  const displayArticles = articles.map(({ node: item }, index) => (
    <ArticlesItem key={item.id} slug={item.slug} date={item.date} title={item.title} image={item.image} id={item.id} />
  ))

  return (
    <section id="newsSection" className="section">
      <div className="container-fluid-small">
        <div className="row">{displayArticles}</div>
      </div>
    </section>
  )
}

export default Articles
