import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { PageLink } from './PageLink'

function ArticlesItem(props) {
  const image = getImage(props.image)
  const [selected, setSelected] = useState(false)

  function onHover() {
    setSelected(true)
  }

  function notHover() {
    setSelected(false)
  }

  return (
    <div role="link" tabIndex="0" onMouseEnter={onHover} onMouseLeave={notHover} className={`col-12 blog-post anim-bot-big ${selected ? 'selected' : null}`}>
      <PageLink to={`/news/${props.slug}`}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 blog-date">
            <h5 className="sub-title">{props.date}</h5>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 blog-title">
            <h2 className="big-title">{props.title}</h2>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 blog-image">
            <GatsbyImage imgClassName="featuredImage" image={image} alt={props.title} />
          </div>
        </div>
      </PageLink>
    </div>
  )
}

export default ArticlesItem
