import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Hero from '../components/Hero'
import Articles from '../components/Articles'
import Layout from '../components/Layout'

function News({ location, data }) {
  const articles = get(data, 'allContentfulPageBlog.edges')
  let heroSubTitle = 'Latest News'
  let heroTitle = "Ready to take your Shopify store to the next level? <br /> Read out expert advice, insider knowledge, and clever strategies."
  return (
    <Layout location={location}>
      <Hero title={heroTitle} sub={heroSubTitle} />
      <Articles articles={articles} />
    </Layout>
  )
}

export default News

export const query = graphql`
  query blogQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPageBlog(sort: {fields: date, order: DESC}) {
      edges {
        node {
          id
          title
          slug
          image {
            gatsbyImageData(
              aspectRatio: 1.5
              layout: FULL_WIDTH
              width: 800
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
          date(formatString: "DD MMMM YYYY")
        }
      }
    }
  }
`
